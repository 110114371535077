function App() {
	const a = window.location.href
	const userAgent = window.navigator.userAgent
	if (
		window.location.href !==
			window.location.origin + window.location.pathname &&
		userAgent.includes('Android')
	) {
		window.location.href =
			'intent://' +
			window.location.host +
			window.location.pathname +
			'#Intent;scheme=https;package=com.android.chrome;end'
	}
	if (
		userAgent.includes('iPhone') ||
		userAgent.includes('iPad') ||
		userAgent.includes('iPod')
		// &&
		// !userAgent.includes('Safari')
	) {
		// Chuyển hướng đến Safari hoặc trình duyệt mặc định
		window.location.href =
			'https://' + window.location.host + window.location.pathname
	}
	return <div>{a}</div>
}

export default App
